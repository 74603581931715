<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 210px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">History Points</span>
        </div>
      </div>

      <div class="hcard relative z-10 p-8">
        <div class="grid grid-cols-1 gap-6 mt-2 animate-pulse" v-if="loading">
          <div class="relative z-10" v-for="n in 3" :key="n">
            <div class="hcard-b17 flex xs:flex-col sm:flex-row p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1">
                  <div class="h-6 w-32 bg-hline rounded-lg"></div>

                  <div class="mt-1">
                    <div class="h-7 w-56 bg-hline rounded-lg"></div>
                  </div>
                  <div class="mt-1">
                    <div class="flex space-x-5">
                      <div class="flex">
                        <div class="h-5 w-28 bg-hline rounded-lg"></div>
                      </div>
                      <div class="flex">
                        <div class="h-5 w-20 bg-hline rounded-lg"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="place-self-center">
                  <div class="h-8 w-18 bg-hline rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-6 mt-2" v-else>
          <div class="col-span-1 py-24" v-if="!points.length">
            <img :src="NoData" class="mx-auto" />
            <div class="capitalize font-semibold text-5xl text-hblue text-center">
              no points yet!
            </div>
          </div>

          <div class="relative z-10" v-for="(p, idx) in points" :key="idx">
            <div class="hcard-b17 h-full">
              <div class="flex">
                <div class="text-sm font-semibold text-white bg-hblue py-0.5 px-7 rounded-tl-17px">
                  from ticket {{ p.ticket_number }}
                </div>
              </div>
              <div class="flex xs:flex-col sm:flex-row px-7 py-3">
                <div class="flex-grow">
                  <div class="grid-cols-1">
                    <div class="font-medium text-sm" v-for="h in p.histories" :key="h.name">
                      {{ h.point }} {{ h.name }}
                    </div>
                    <div class="font-semibold text-htextdark -ml-3 pt-2">
                      {{ user.name }}
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="place-self-center">
                    <div class="text-htextdark text-2xl text-center font-semibold">
                      {{ p.point }}
                    </div>
                    <div class="text-htextdark font-semibold text-xs text-center">Points</div>
                    <div class="text-xs text-center pt-4">
                      {{ p.created_at }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center pt-7">
            <button
              class="btn btn-primary h-9.3 mx-auto capitalize border-0 hover:bg-hblue"
              :disabled="loading"
              @click="changePage"
            >
              <span>Load more</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import NoData from '@/assets/images/icon-noMessage.png'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'points',
  components: {
    IconArrow
  },
  data() {
    return {
      NoData,

      loading: true,
      offset: 0
    }
  },
  computed: {
    ...mapState({
      points: state => state.notif.points,
      user: state => state.auth.user
    })
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('notif', ['GET_POINTS']),
    toTicket(val) {
      if (val && val.data) {
        let removeSpacing = val.data.number ? val.data.number.replaceAll(/\s+/g, '-') : ''
        let removeSlash = removeSpacing.replaceAll('/', '')
        this.$router.push({ name: 'ticket.detail', params: { slug: removeSlash, id: val.data.id } })
      }
    },
    async getData() {
      this.loading = true
      let que = JSON.parse(JSON.stringify(this.$route.query))

      await this.GET_POINTS(que)

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    changePage() {
      this.offset += 10
      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.t = new Date().getTime()
      queries.offset = this.offset
      queries.limit = 10
      queries.detail = 1

      this.$router.replace({ query: queries })
    }
  }
}
</script>
